import { isEmpty, replace } from "lodash";
import moment from "moment";

export const formatPhone = (phone: string, usCountyCode?: string): string => {
  if (isEmpty(phone)) {
    return "";
  }

  let formattedPhone = phone.replace(/\D+/g, "");

  if (formattedPhone.length !== 10) {
    return phone;
  }

  formattedPhone = formattedPhone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");

  if (usCountyCode) {
    formattedPhone = `${usCountyCode} ${formattedPhone}`;
  }

  return formattedPhone;
};

export const getPhoneWithoutCode = (phone: string) => {
  if (isEmpty(phone)) {
    return "";
  }

  return replace(phone, /\D+/g, "").slice(-10);
};

/**
 * @deprecated - use lodash util
 * Returns a string with first letter capital
 * @param {as type string} string
 */
export const capitalizeFirstLetter = (string?: string) => {
  if (!string || typeof string !== "string") {
    return string;
  }

  return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * Returns a human readable string for a tag/method string
 * @param {tag like 'RUSH_FEE' as string} tag
 */
export const getHumanReadableTag = (tag?: string) => {
  if (!tag || typeof tag !== "string") {
    return tag;
  }

  return tag
    .split("_")
    .map((splittedTag) => {
      return capitalizeFirstLetter(splittedTag.toLowerCase());
    })
    .join(" ");
};

/**
 * @deprecated use a standard date-fns duration format util.
 * return time in number to hrs minutes string;
 * 1.5 returned as 1 hr 30 mins;
 * 0.5 returned as 30 mins
 */
export const getHumanReadableTime = (totalHours: number): string => {
  const duration = moment.duration(totalHours, "hours");
  const hours = duration.hours();
  const minutes = duration.minutes();
  let formattedTimeRange = "";
  if (hours > 0) {
    if (hours > 1) {
      formattedTimeRange = `${hours} hrs`;
    } else {
      formattedTimeRange = `${hours} hr`;
    }
  }
  if (minutes > 0) {
    const minuteLabel = minutes === 1 ? "min" : "mins";
    if (formattedTimeRange) {
      formattedTimeRange = `${formattedTimeRange} ${minutes} ${minuteLabel}`;
    } else {
      formattedTimeRange = `${minutes} ${minuteLabel}`;
    }
  }
  return formattedTimeRange;
};

/**
 * @deprecated - use lodash titleize(content)
 */
export const capitalizeFirstLetterOfEveryWord = (text: string) => {
  if (isEmpty(text)) {
    return "";
  }

  return text.replace(/(?:^|\s)\S/g, (res) => {
    return res.toUpperCase();
  });
};
